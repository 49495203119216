import React, { useState, useContext, useEffect } from "react"
import ROSLIB from "roslib"
import Context from "../utils/Context"
import api from "../api/api"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faAndroid, faRobot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import BatteryStatus from "./BatteryStatus"
import DiagnosticStatus from "./DiagnosticStatus"

export default function RobotSelector() {


  const context = useContext(Context)

  // state
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    // console.log("useEffect selectedRobot", context.selectedRobot)

    if (context.selectedRobot) connectRobot()
  }, [context.selectedRobot, context.actions, context.isRobotConnected])


  // select robot
  const selectRobot = async (robot, typeRobot) => {

    context.actions.setModalState({ ...context.modalState, settingsRemote: false })

    context.actions.setSelectedRobot(robot)
    context.actions.setSelectedTypeRobot(typeRobot)
    // get robot info from FMS, if using it
    if (context.settings.fmsConnection) {
      try {
        const robotInfo = async () => {
          api.getRobotInfo(robot.name)
            .then((res) => {
              // console.log("got fms robot info", res.data[0])
              context.actions.setRobotInfo(res.data[0])
            }).catch((error) => {
              context.actions.setErrorMessage(error.response.status)
              context.actions.setModalState({ ...context.modalState, disconnect: true })
            })
        }

        robotInfo()

        // context.actions.setRobotInfo(robotInfo.data[0])
      } catch (error) {
        console.log("RobotSelection: login error ", error)
      }
    }

  }

  // connect selected robot ip address to ROS
  const connectRobot = () => {

    if (!context.isRobotConnected && context.selectedRobot) {
      context.actions.setAcceleratorValue(50)
      setIsLoading(true)
      //window.connectionInterval = setInterval(() => {

        if (!window.rosConnected) {
          window.ros = new ROSLIB.Ros({ url: "ws://" + context.selectedRobot.ip + ":9090" })

          window.ros.on("connection", () => {
            console.log(
              "Connected to websocket server.",
              `${context.selectedRobot.ip}:9090`
            )
            window.rosConnected = true
            context.actions.setIsRobotConnected(true)
            setIsLoading(false)
          })

          window.ros.on("error", function (error) {

            disconnect()
            context.actions.setErrorMessage("disconnect")
            context.actions.setModalState({ ...context.modalState, disconnect: true })
            console.log("Error connecting to websocket server")
            //clearInterval(window.connectionInterval)

          })

          window.ros.on("close", function () {

          //  console.log("Connection to websocket server closed.")
            setIsLoading(false)
            disconnect()
          })
        }
      //}, 10000)
    }
  }

  const disconnect = () => {

    clearInterval(window.connectionInterval)
    window.ros.close()
    window.rosConnected = false
    context.actions.setSelectedRobot(false)
    context.actions.setSelectedTypeRobot(null)
    context.actions.setIsRobotConnected(false)

  }


  const availableRobots = context.robotConfiguration
  if (!availableRobots) return null

  return (
    <div className="field has-addons">
      <div className="control is-expanded">
        <div className="is-fullwidth">
          <div className="control columns">
            {context.selectedRobot &&
              <div className="column">
                {context.diagnosticSwitch &&
                  <span className="icon is-small">
                    <DiagnosticStatus />
                  </span>
                }
                <BatteryStatus />
              </div>
            }
            <div className="column">

              {availableRobots &&
                availableRobots.map((item, i) => (

                  <button
                    key={i}
                    type="submit"
                    className={isLoading && !(context.selectedRobot && (context.selectedRobot?.name !== item.name)) ? "button is-link is-warning is-loading mb-2 ml-2"
                      : (context.selectedRobot && (context.selectedRobot?.name !== item.name)) ? "button is-hidden"
                        : context.isRobotConnected ? "button is-danger mb-2 ml-2" : "button is-warning is-inverted mb-2 ml-2"}
                    onClick={() => (context.selectedRobot ? disconnect() : selectRobot(item))}
                    disabled={context.selectedRobot && (context.selectedRobot?.name !== item.name) ? "disabled" : ""}
                  >
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={context.isRobotConnected ? faPowerOff : faRobot} className="fas" />
                    </span>
                    <span>
                      {context.isRobotConnected ? "Disconnect " + context.selectedRobot?.name : isLoading ? "Connection..."
                        : (!context.scanRobots && context.robotConfiguration) ?
                          availableRobots.find(element => element.ip === item.ip)?.name
                          : "Robot " + item.ip.split(".")[3]}
                    </span>
                  </button>

                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
